import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>El Libro</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        Os presentamos el nuevo libro Las 500 preguntas imprescindibles para ser
                        Guardia Civil.
                        <br />
                        <br />
                        Un libro que os ofrece la base de vuestro estudio y que os colocará entre
                        los mejores. Totalmente actualizado a la convocatoria de 2021. Las 500
                        preguntas que debes conocer para superar con éxito la prueba de temario.
                        <br />
                        <br />
                        Os dejamos enlace con los 2 primeros capítulos para que veáis un poco como
                        es. La app iGC tiene también un módulo de pago a 9,99€ con más de 8.000
                        preguntas clasificadas según los temas de las bases.
                        <br />
                        <br />
                        El precio del libro más la app es de 34,99€; por la compra del libro se
                        activa de forma gratuita durante un año un módulo dentro de la app iGC, que
                        contiene las mismas preguntas pero en modo test, para que puedas practicar
                        como si se tratase del examen real.
                        <br />
                        <br />
                        Ya disponible en <a href="https://www.iopos.es/tienda">iopos.es/tienda</a>
                        <br />
                        <br />
                        🖖 saludos y 🍀 en la Opo.
                    </Text>
                </div>
            </div>

            {/** Preview del libro */}
            <div className="container lg:px-0 py-20">
                <Title>Preview del libro</Title>
                <Text className="text-justify">
                    A continuación os adjuntamos un resumen en formato pdf del contenido del libro.
                </Text>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/98/libro-gc-preview.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    ></embed>
                </div>
            </div>

            {/** Cómo adquirirlo */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
